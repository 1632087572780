import { Injectable } from '@angular/core';
import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';
import { AppVersionService } from '../services/app-version-service';
import { environment } from 'src/environments/environment';
import { AppConsts } from '../app-consts';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

@Injectable()
export class FaroInitializerService {

  versionNumber: string;
  environmentName: string;
  grafanaUrl: string;

  constructor(appVersionService: AppVersionService) {
    const isEnabled: boolean = environment[AppConsts.GrafanaConfigurationName].isEnabled;
    if (!isEnabled) {
      return;
    }
    
    this.versionNumber = appVersionService.GetAppVersion();
    this.environmentName = environment['name'];
    this.grafanaUrl = environment[AppConsts.GrafanaConfigurationName].url;
    this.initialize();
  }

  public initialize(): void {
    initializeFaro({
      url: this.grafanaUrl,
      app: {
        name: AppConsts.AppName,
        version: this.versionNumber,
        environment: this.environmentName,
      },
      instrumentations: [
        ...getWebInstrumentations({ captureConsole: true, captureConsoleDisabledLevels: [] }),
        new TracingInstrumentation()
      ],
    });
  }

}
